
.footer{
    background-color: #15294f;
    color: #b1b1b1;
    height: 330px;
    padding: 50px 0;
}
.container{
    margin: auto;
    width: 90%; 
    overflow: hidden;
}
.footerContent{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.footerSide {
    width: 35%;
    margin-top: 65px;
    margin-left: 5%;
}
.footerSide img{
    width: 70%;
    height: 55%;
    margin-bottom: 10px;
}
.lists {
    display: flex;
    justify-content: center;
}
.lists ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.lists ul li {
    text-decoration: none;
    line-height: 25px;
    font-size: 11px;
}

.linksli {
    color: #b1b1b1;
    font-size: 11px;
    text-decoration: none;
    line-height: 25px;
}

.linksli:hover {
    color: #fff;
    text-decoration: underline;
}


.list{
    padding: 40px;
    align-items: center;
}

.subLists {
    display: flex;
    justify-content: center;
  }

#list1, #list2 {
    width: 30%; /* Adjust as needed */
  }
  
  #list3 {
    width: 40%; /* Adjust as needed */
  }

.list ul{
    list-style-type: none;
}
.footer .list h3{
    color: #fff;
    margin-bottom: 10px;
    font-size: 14px;
    position: relative;
}

.footer p{
    color: #b1b1b1;
    font-size: 12px;
}
.icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list .call {
    border: 2px solid rgb(27, 47, 84);
    font-size: 10px;
    padding: 10px 25px;
    /* box-shadow: 0px 10px 20px rgba(94, 93, 93, 0.3); */
    box-shadow: 0px 0px 5px rgba(94, 93, 93, 0.4);
    transition: all 0.3s ease;
    cursor: pointer;
}

.list .call:hover {
    transform: translateY(-3px);
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid #c0c0c0a1;
    background-color: rgb(17, 34, 66);
}

.call span{
    margin-right: 10px;
}
.list li span {
    border: 1px solid rgb(27, 47, 84);
    font-size: 15px;
    padding: 5px;
    color: #eee;
    cursor: pointer;
}

  .end {
    background-color: #0f1e3b;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .endInfo ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
  }
  
  .endInfo ul li {
    color: #58606b;
    margin: 0;
    font-size: 12px;
    text-transform: uppercase;
  }

  .noUnderline {
    text-decoration: none;
  }

  .call a {
    text-decoration: none;
  }

  @media (max-width: 1000px) {
    .footer {
        height: auto;
        overflow: visible;
    }
    .lists {
        flex-direction: column;
        align-items: center;
      }

  }
  
@media (max-width: 768px) {
    .footerContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .footerSide {
        width: 100%;
        margin-left: 15% !important;
    }

    .end {
        height: auto;
    }

    .end .container .endInfo ul {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .end .container .endInfo ul li {
        margin: 10px 0; 
    }
}