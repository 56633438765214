.numbers{
    display: flex;
    margin-top: 2%;
    padding: 15%; /*Change for the numbers substitute*/
}

.numbers div{
    margin: 5px;
    text-align: center;

}

.numbers p  
{
    font-weight: 500;
    font-size: 17px; /*Change for the numbers substitute*/
}

.numbers div p
{
    font-weight: 500;
    font-size: 18px; 
}
.numbers div h3{
    font-weight: 500;
    font-size: 28px;
}

/* Add the following to your existing CSS */
@media (max-width: 1000px) {
  .numbers {
    align-items: center;
    padding: 5%;
    height: 40rem !important;
  }

  .numbers div {
    margin: 0 0;
  }

  .numbers div p {
    font-weight: 500;
    font-size: 12px;
    margin: 0;
  }

  .numbers div h3 {
    font-weight: 500;
    font-size: 24px;
  }
}

@media (max-width: 768px) {
    .numbers {
      align-items: center;
      padding: 5%;
      height: 28rem;
    }

    .numbers div {
      margin: 0 0;
    }

    .numbers div h3 {
      font-size: 14px;
    }

    .numbers div p {
      font-size: 10px;
      margin: 0;
    }

    .numbers p  
    {
      font-weight: 500;
      font-size: 14px; /*Change for the numbers substitute*/
    }
  }
  