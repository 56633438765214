
.secTwo {
  background-image: url('../../../public/img/info3-back.png');
  background-color: #f9fcfd;
}

.secNine {
  background-image: url('../../../public/img/info-back.png');
  background-color: #f9fcfd;

}

.secondSec {
  /* background-color: #f9fcfd; */
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.secondSec h2 {
  margin: 75px auto;
  width: 60%;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
}

.ninthSec {
  /* background-color: #f9fcfd; */
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.ninthSec h2 {
  margin: 75px auto;
  width: 40%;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
}

.ourMission {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.ourMission div {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ourMission div:hover {
  background-color: #eceef0;
}

.ourMission h5 {
  font-size: 20px;
  margin: 20px;
  font-weight: 700;
}

.ourMission p {
  font-size: 17px;
}

.ourMission span {
  font-size: 35px;
  color: #001862;
}

.ourMission span:hover {
  color: #41b69d;
  cursor: pointer;
}



.ourMission2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.ourMission2 div {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ourMission2 div:hover {
  background-color: #97e7d5;
}

.ourMission2 h5 {
  font-size: 20px;
  margin: 20px;
  font-weight: 700;
}
.ourMission2 p {
  font-size: 17px;
}


.ourMission2 span {
  font-size: 40px;
  color: #001862;
}

.ourMission2 span:hover {
  color: #001862;
  cursor: pointer;
}

.firstBtn {
  display: flex;
  justify-content: center;
  margin: 30px;
}

@media only screen and (max-width: 1200px) {
  .secondSec {
    width: 80%; 
  }

  .ourMission,
  .ourMission2 {
    grid-template-columns: repeat(2, 1fr); 
  }


  .secondSec h2 {
    font-size: 35px;
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .secondSec {
    width: 95%; 
  }
  
  .ourMission p,
  .ourMission2 p {
    font-size: 15px;
  }
  .ourMission,
  .ourMission2 {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
    margin: auto;
  }

  .ourMission span,
  .ourMission2 span {
    font-size: 30px !important;
  }

  .secondSec h2 {
    font-size: 25px;
    width: 70%;
  }
  .firstBtn {
    font-size: 12px;
  }
}


/* *----------------------Start section 3 ------------------------- */

.secThree {
  background-image: url('../../../public/img/info2-back.png');
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.thirdSec h2 {
  margin: 75px auto;
  width: 60%;
  font-weight: 700;
  font-size: 40px;
}

@media only screen and (max-width: 768px) {

  .secThree {
    height: auto;
  }

  .thirdSec h2{
    font-size: 30px;
  }
  .thirdSec button {
    font-size: 12px;
  }
}
/* *----------------------End section 3 --------------------------- */

/* *-------------------Start section_our vision -------------------- */
.customerTestSec {
  background: linear-gradient(to right, rgba(0, 24, 98, 1), rgba(92, 176, 180, 1));
  /* background: linear-gradient(to right, #b8faec, #eceef0); */
  /* background-color: #b8faec; */
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
}

.ourVisionSec {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
}

.ourVisionSec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: relative;
}

.ourVision {
  background-color: rgba(0, 24, 98, 0.7);
  width: 27%;
  color: #fff;
  height: auto;
  border-radius: 10px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 60px 50px;
}

.ourVision span{
  color: #41b69d;
  font-size: 13px;
  font-weight: 600;
}

.ourVision h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ourVision p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 14px;
}

.ourVision button {
  margin: 0;
}

@media only screen and (max-width: 1024px) {
  .ourVision {
    width: 35%;
    padding: 40px 30px;
    left: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .ourVision {
    width: 85%;
    padding: 30px 20px;
  }
  .ourVision button {
    font-size: 10px;
  }
}
/* *------------------End section_our vision ---------------------- */

/* *----------------------Strat section 4 --------------------------- */

.secFour,
.secSix {
  margin: 70px 0 40px 0;
  background-image: url('../../../public/img/info2-back.png');
}
.content {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  gap: 40px;
}
.leftSide img {
  width: 90%;
  margin-top: 0;
}
.rightSide h2 {
  font-size: 30px;
  font-weight: 700;
}
.divs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.divs h5 {
  font-size: 16px;
  margin: 20px 20px 0px 20px;
  font-weight: 700;
}

.divs .icon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  color: #2849ad;
}

.divs .cont{
  width: 100%;
}

.divs p {
  font-size: 15px;
  margin: 20px;
}

@media only screen and (max-width: 768px) {
  .content {
    width: 90%;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .divs span {
    width: 80px;
    height: 40px;
    font-size: 20px;
    line-height: 30px;
  }
}
/* *----------------------End section 4 --------------------------- */

/* *----------------------Start section 5 --------------------------- */
.secFive {
  position: relative;
  /* height: 90vh; */
}
/* *----------------------End section 5 --------------------------- */

/* *----------------------Start section 8 --------------------------- */
.secEight img {
  width: 75%;
  margin-left: 5%;
}

.secEight {
  background-color: #f0fbfd;
  position: relative;
}
.secEight button {
  background-color: #2849ad;
}
.secEight button:hover {
  background-color: #0d6efd;
}
.secEight .dots {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 10%;
  top: 20px;
}

.questions {
  margin: auto;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoSide {
  width: 40%;
}

.questions .rightSide h2 {
  margin: 70px 0 40px 0;
}

@media (max-width: 768px) {
  .secEight {
    padding: 20px;
  }

  .secEight img.dots {
    left: 5%;
  }
  .questions {
    flex-direction: column;
  }

  .logoSide {
    display: none;
  }
  .rightSide button {
    font-size: 12px;
  }
}
/* *----------------------End section 8 --------------------------- */

/* *----------------------Start section 9 ------------------------- */
.secNine h2 {
  color: #287262;
}
.secNine span {
  color: #41b69d;
}

@media (max-width: 1100px){
  .secNine h2{
    font-size: 25px;
    margin: 20px;
    width: 80%;
  }
}
/* *----------------------End section 9 --------------------------- */

/* *----------------------Start section 10 ------------------------- */
.impNumber {
  margin-top: 70px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  color: #fff;
  background-image: url(../../../public/img/banner.png);
  height: 30rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.impNumber h3 {
  font-size: 30px;
  font-weight: 500;
  margin: 0;
}

@media (max-width: 1000px) {
  .impNumber h3{
    font-size: 26px;
    font-weight: 500;
  }
}

@media (max-width: 768px) {
  .impNumber {
    height: auto;
    padding: 45px;
    margin-top: 40px;
  }

  .impNumber h3 {
    font-size: 18px;
    margin: 0;
  }
}
/* *----------------------End section 10 --------------------------- */

/* *----------------------Start section 11--------------------------- */
.sec11 {
  background-color: #ffffff;
  padding: 50px;
  position: relative;
}

.sec11::before{
  content: url('../../../public/img/colred-2-Ls.png');
   position: absolute;
   top: 25%;
   left: 15px;
}

.sec11::after{
  content: url('../../../public/img/colred-1-Ls.png');
  position: absolute;
  top: 5%;
  right: 30px;
}

.contact {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}
.contactForm {
  background-color: transparent;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #dfdfdf;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.informations {
  background-color: transparent;
  padding: 40px;
  padding-top: 20px;
  border-radius: 20px;
  border: 1px solid #dfdfdf;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
}
.contact .contactForm h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 2px solid #dfdfdf;
}
.contact .infoContent {
  margin: 40px 15px;
  font-size: 14px;
}

.contact .informations h5 {
  top: 0;
  padding-bottom: 15px;
  padding-top: -10px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 2px solid #dfdfdf;
}
.informations div {
  margin-top: 20px;
}
.informations span {
  padding-right: 15px;
  font-size: 18px;
  color: #a6a6a6;
}

@media (max-width: 1100px) {
  .contact {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  .contactForm{
    padding: 25px;
  }
  
  form{
    padding: 0;
  }

  .sec11 {
  padding: 25px;
}

  .sec11 .title {
    font-size: 40px;
    top: -20%;
  }
  .sec11::before,
  .sec11::after {
    display: none;
  }
}