.topDetails {
  width: 100%;
  height: 150px;
  background-color: #7acfd3;
  color: #15294f;
  display: flex;
  align-items: center;
  justify-content: left;
}

.topDetailsContent {
  display: flex;
  align-items: left;
  width: 80%;
  margin-left: 50px;
}

.topDetailsText {
  margin-left: 10px;
}
.topDetailsText span {
  font-size: 24px;
  font-weight: 600;
}

.jobDetailsLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background-color: #fff;
  border-radius: 50%;
}

.jobDetailsLogo img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.topDetailsText h4 {
  font-size: 18px;
  font-weight: 700;
}

.details {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.detailOne {
  margin-bottom: 30px;
}
.detailOne h5,
.detailTwo h5 {
  font-weight: 700;
}
.detailTwo {
  width: 70%;
}
.detailTwo p {
  margin: 20px 0;
}
.detailOne ul span {
  color: #446688;
  padding-right: 5px;
}
.detailOne ul {
  padding-left: 0;
}
.detailOne li,
.detailTwo p {
  font-size: 13px;
  list-style: none;
  margin-top: 10px;
}
.detailTwo button {
  margin: 0;
  margin-right: 20px;
}
.bottomDetail {
  background-color: #f4f4f4;
  padding: 30px;
  margin: auto;
}
.bottomDetail h4 {
  color: #446688;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomDetail .jobSlider {
  padding: 25px;
}
