
.reviewProductContainer {
  margin: auto;
  width: 75%;
}
.cardReviewed {
  display: flex;
  justify-content: space-around;
  margin: 60px 0;
}
.detailsRelated_pr {
  display: flex;
  justify-content: space-between;
}
.relatedSlider {
  width: 25%;
}

.relatedSlider h2 {
  color: #2ca889;
  font-size: 24px;
  font-weight: 800;
  margin: 25px 0;
  margin-left: 15px;
  text-shadow: 2px 2px 4px rgba(44, 168, 137, 0.25);
}

.pr_detail {
  width: 220px;
  height: auto;
}
.pr_detail img {
  border-radius: 10px;
}
.pr_detail h2 {
  color: #2849ad;
  font-size: 20px;
  font-weight: 700;
  margin: 15px 0;
}
.pr_detail p {
  font-size: 12px;
  color: #666;
}
.pr_detail h6 {
  color: #00477b;
  font-weight: 700;
  margin: 15px 0;
}

.otherProductBtn {
  background-color: #2849ad;
  color: #fff;
  font-size: 12px;
  border-radius: 6px;
}

.otherProductBtn:hover{
  font-weight: 700;
  color: #2849ad;
  background-color: #fff;
  border: 2px solid #2849ad;
  transition: background-color 0.3s ease;
}
.pr_detail .pr_detail_img {
  width: 100%;
  height: 188px;
}

/** ----------------------------------------------------*/
.pr_info h2,
.pr_reviews h2,
.pr_requirements h2,
.pr_needed h2 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 18px;
}
.pr_info ul span,
.pr_needed ul span {
  color: #2849ad;
  padding-right: 5px;
  font-size: 15px;
}
.pr_info li,
.pr_needed li,
.pr_requirements li {
  font-size: 13px;
  margin: 6px 0;
}

.pr_reviews,
.pr_requirements {
  margin: 40px 0;
}
.pr_requirements span {
  color: #2849ad;
  padding-right: 5px;
  font-size: 10px;
}

/* =====================Rating===================== */
.rating {
  margin: 30px 0;
}
.rating h2 {
  font-weight: 700;
  font-size: 18px;
}
.userRating {
  margin-top: 10px;
  width: 65%;
  border-bottom: 1px solid #eee;
}
.rating .starContainer {
  display: flex;
  align-items: center;
}
.userRating .clintComment {
  color: rgb(121 121 123);
  font-size: 12px;
  margin-bottom: 10px;
}
.stars,
.wholeRating {
  margin-right: 10px;
  color: goldenrod;
}
.userRating span,
.rating span {
  font-size: 10px;
  font-weight: 700;
}
.rating .btnRating {
  border: 1px solid #2849ad;
  color: #00477b;
  font-weight: 700;
  font-size: 10px;
  border-radius: 6px;
  padding: 7px 30px;
  margin-top: 20px;
  transition: all 0.3s ease;
}
.rating .btnRating:hover {
  background-color: #2849ad;
  color: #fff;
}
/* =====================Rating===================== */

/**-------------------------Other Product----------------------------*/
.otherProducts,
.brandSlider {
  background-color: #f4f4f4;
  text-align: center;
  margin-top: 60px;
}

.otherProducts h2,
.brandSlider h2 {
  padding: 30px 0;
  font-size: 25px;
  font-weight: 700;
}
.otherProductBtn {
  padding: 13px 40px;
  margin-bottom: 60px;
}

/**-------------------------Other Product----------------------------*/

@media only screen and (max-width: 992px) {
  .cardReviewed {
    display: flex;
    flex-direction: column;
  }
  .detailsRelated_pr {
    display: flex;
    flex-direction: column;
  }
  .relatedSlider {
    width: 100%;
  }
  .pr_detail {
    margin: 10px auto;
  }
  .otherProducts {
    margin-top: 40px;
  }
  .otherProducts h2 {
    font-size: 20px;
  }
  .otherProductBtn {
    padding: 10px 20px;
  }
}
