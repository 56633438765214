
.custom-width {
    width: 500px; 
    max-width: 1000px; 
    margin: 0 auto;
    background-color: #fff;
  }


.inputArea {
    background-color: #f4f6f8;
    box-shadow:  0px 0px 10px rgba(0, 0, 0, 0.05)
}

.inputArea::placeholder {
  color: #4bad94;
}

.inputAreaPadding {
  margin-bottom: 20px;
}

.button-no-border {
  border: none;
}

input[type="date"].inputArea::-webkit-datetime-edit {
    background-color: #f4f6f8;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    color: #4bad94;
}

.form-control.custom-select {
  background-color: #f4f6f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  color: #4bad94;
  border-radius: 4px;
  height: 35px;
}

.form-control.custom-select:focus {
  background-color: #f4f6f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 10px;
}

.form-control.custom-select option {
  padding: 6px 12px;
  color: #2849ad;
}

.form-control.custom-select option:checked {
  background-color: #7acfd3;
  color: #fff;
}

















































.nav-tabs .nav-link {
  background-color: white;
  color: #5c636669 !important;
  font-weight: 600;
  font-size: 12px;
}

.nav-tabs .nav-link.active {
  background-color: white;
  color: #2849ad!important;
  border-bottom: none !important;
  font-weight: 600;
  font-size: 12px;
}


.nav-tabs .nav-link .tab-title-container.visited {
  background-color: white;
  color: #4bad94 !important;
  font-weight: 600;
  font-size: 12px;
}

.nav-tabs .nav-link.active {
  border-bottom: 3px solid #2849ad!important;
}

.nav-tabs .nav-link .tab-title-container.active {
  background-color: white;
  color: #2849ad!important;
  border-bottom: none !important;
  font-weight: 600;
  font-size: 12px;
}

.nav-tabs .nav-link .tab-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.nav-tabs .nav-link .tab-title-container .tab-icon{
  font-size: 20px;
}

.nav-tabs .nav-link .tab-title-container .tab-small-text {
  color: #869297;
}

.button3D {
  background-color: #2849ad;
  box-shadow: 0 5px #f4f6f8;
  font-weight: 600;
  font-size: 16px;
  transform: translateY(4px);
}

.button3D:hover {
  background-color: #5cb0b4;
  border-color: white;
}


.button3D:active {
  box-shadow: 0 2px #f4f6f8;
  transform: translateY(2px);
}

.bottom-note {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bottom-note p {
  margin-right: 20px;
  font-weight: 600;
}

.bottom-note span {
  margin-top: 5px;
  margin-right: 20px;
  font-weight: 600;
}

.a-tag1 {
  margin-left: 5px;
  color: rgb(255, 49, 49);
}

.a-tag2 {
  margin-left: 45px;
  font-weight: 600;
}


.btnLogin {
  border: 2px solid #7acfd3;
  color: #7acfd3;
  background-color: #fff;
  width: 25%;
  margin-left: 25px;
}
.btnLogin:hover {
  background-color: #7acfd3;
  color: #fff;
  border: 2px solid #7acfd3;
}

@media (max-width: 1200px) {
  .nav-tabs .nav-link,
  .nav-tabs .nav-link.active,
  .custom-width,
  .nav-tabs .nav-link .tab-title-container.active,
  .nav-tabs .nav-link .tab-title-container.visited {
    background-color: transparent;
  }
  
}

@media (max-width: 768px) {

  .nav-tabs .nav-link,
  .nav-tabs .nav-link.active {
    background-color: transparent;
  }

  .custom-width {
    width: 350px;
    background-color: transparent;
  }

  .bottom-note {
    flex-direction: column;
  }
  .a-tag2 {
    margin-left: 0;
  }
}
