.tslider-container {
  margin-top: 7% !important;
  gap: 25px;
}


/* Card.css */
.tcard {
  width: 23rem !important;
  height: 18rem !important;
  /* background-color: #f0f3f7; */
}


/* Card.css */
.tcard:hover {
 background-color: #f6fbff;
}

.tcard-image {
  width: 65px !important;
  height: 65px !important;
  align-self: flex-start;
}

.tcard-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 20rem;
  gap: 15px;
}

.middle-text {
  font-size: large !important;
  text-align: center;
}

.bottom-text {
  font-size: small !important;
  font-weight: bold !important;
  align-self: flex-end;
}

.middle-text:hover {
  color: #001862;
  font-size: larger !important;
}

.bottom-text:hover {
  color: #001862;
  font-size: medium !important;
}



@media (max-width: 1350px) {
  .tslider-container {
    margin-top: 13% !important;
  }

  /* Card.css */
.tcard {
  width: 10rem !important;
  height: 16rem !important;
}

.tcard-image {
  width: 40px !important;
  height: 40px !important;
}

.tcard-body {
  height: 15rem;
  gap: 5px;
}

.middle-text {
  font-size: medium !important;
}

.bottom-text {
  font-size: smaller !important;
}
}


@media (max-width: 1100px) {
  .tslider-container {
    margin-top: 13% !important;
  }

  /* Card.css */
.tcard {
  width: 20rem !important;
}

.tcard-image {
  width: 50px !important;
  height: 50px !important;
}

.tcard-body {
  height: 15rem;
  gap: 5px;
}

.middle-text {
  font-size: medium !important;
}

.bottom-text {
  font-size: smaller !important;
}
}

@media (max-width: 789px) {
  .tslider-container {
    margin-top: 25% !important;
  }
}

