.successMessage {
    width: full;
    text-align: center;
    margin-top: 20px;
    font-weight: 600;
    color: #4ab590;
  }


  .failureMessage {
    width: full;
    text-align: center;
    margin-top: 20px;
    font-weight: 600;
    color: orange;
  }

  .rowMargin {
    margin: 5px;
  }

  .emailMargin {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .inputBox {
    border: 1px solid #e8ecf0;
    background-color: #f4f6f8; 
  }
  
  .inputBox::placeholder {
    color: #888;
    font-weight: 300;
    font-size: 14px;
  }

  .divBtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button3D {
    background-color: #2849ad;
    box-shadow: 0 5px #f4f6f8;
    transform: translateY(4px);
  }

  .button3D:hover {
    background-color: #5cb0b4;
    border-color: white;
  }
  
  
  .button3D:active {
    box-shadow: 0 2px #f4f6f8;
    transform: translateY(2px);
  }