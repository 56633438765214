.product-slider {
  width: 100% !important;
  margin: 5px 0 5px 0;
  left: 10%;
  font-size: 12px;
  padding: 0;
  border: 0;
}

.job-col {
  padding: 0px !important;
}

div .CareerListings_productSlider__vhnm8 div div {
  justify-content: center !important;
}
div .CareerListings_productSlider__vhnm8 div div div {
  padding: 5 !important;
}

.card-body p,
.card-body h6 {
  font-weight: 700;
}

.card-body p {
  font-size: 10px;
}

.card-body h6 {
  margin-top: 10px;
  font-size: 14px;
}

.card-body ul {
  padding: 5px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #666;
}
.card-body ul li {
  margin: 5px;
}

.topCardContent {
  display: flex;
}

.topCardContent p {
  margin-left: 15px;
}

.ccardbtn{
  background-color: #ebf5ff;
  border: 0;
  padding: 8px 10px;
  margin: 15px 0 15px 0;
  color: #2b5279;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  float: left;
  font-size: 12px;
  font-weight: 800;
  }
  
  .ccardbtn:hover{
      background-color: #2b5279;
      color: #fff;
      transition: all .3s;
  }



/*---------------- custom-pagination---------------- */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 ;
}
.pagination li {
  margin: 5px;
}
.pagination li a {
  color: #444;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin: 10px;
  text-decoration: none;
}

.pagination .active {
  background-color: #2849ad;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.pagination .active a {
  color: #fff;
}
