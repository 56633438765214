@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.aboutus {
  background-image: url("../../../public/img/info-back.png");
  background-repeat: repeat;
}

.aboutus img {
  height: auto;
  width: 100%;
  object-fit: cover;
}
.toptInfo {
  width: 80%;
  margin: auto;
  padding: 40px;
}

.aboutus .title {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 30%;
  left: 35%;
  font-size: 50px;
  transform: translate(-20%, -20%);
  color: #fff;
  margin: 0px;
  justify-content: center;
  text-align: center;
}

.aboutus .title2 {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 35%;
  left: 40%;
  font-size: 50px;
  color: #fff;
  margin: 0px;
  justify-content: center;
  text-align: center;
}

.aboutus .subTitle {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 45%;
  left: 44%;
  transform: translate(-20%, -20%);
  font-size: 50px;
  color: #fff;
  margin: 0px;
}

.aboutus .subTitleC {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 45%;
  left: 35%;
  transform: translate(-20%, -20%);
  font-size: 50px;
  color: #fff;
  margin: 0px;
}

@media (max-width: 1000px) {
  .aboutus .title {
    top: 30%;
    font-size: 40px;
  }

  .aboutus .subTitle {
    top: 40%;
    font-size: 35px;
  }

  .aboutus .title2 {
    top: 30%;
    font-size: 40px;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .aboutus .title {
    top: 30%;
    font-size: 30px;
  }

  .aboutus .subTitle {
    top: 25%;
    font-size: 25px;
  }

  .aboutus .title2 {
    left: 30%;
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .aboutus .title {
    top: 30%;
    font-size: 24px;
  }

  .aboutus .subTitle {
    top: 40%;
    font-size: 24px;
  }

  .aboutus .title2 {
    top: 20%;
    left: 30%;
    font-size: 40px;
    font-weight: 500;
  }
}

.paragraph {
  font-size: 16px;
  margin: 50px;
  text-align: center;
  font-weight: 500;
}

/* The new Design
Add background colors
Add background watermarks/Images */
.dsm {
  margin: 30px;
  height: 130px;
}
.dsmtitle {
  font-family: "Open Sans", sans-serif;
  position: absolute;
  top: 25%;
  left: 16%;
  font-size: 32px;
  font-weight: 700;
  color: black;
  margin: 0px;
  justify-content: left;
  text-align: left;
}

@media (max-width: 768px) {
  .aboutus .title {
    font-size: 30px;
    top: 15%;
  }
  .toptInfo {
    font-size: 14px;
    width: 100%;
    margin: 0;
  }
}
