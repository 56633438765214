.card-body p,
.card-body h6 {
  font-weight: 700;
}
.card-body ul {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  color: #444;
    margin-bottom:0;
    font-size: 12px;
}
.card-body ul li {
  margin: 5px;
}
.topCardContent {
  display: flex;
}
.topCardContent p {
  margin-left: 15px;
  font-size: 15px;
}
