.career_info_card_body {
    width: 40rem !important;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.career_info_card_title {
    margin: auto;
    font-weight: 600;
    font-size: 18px;
}


@media screen and (max-width: 1500px) {
    .career_info_card_body {
        width: 30rem !important;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f4f4f4;
    }
  }


  @media screen and (max-width: 1300px) {
    .career_info_card_body {
        width: 25rem !important;
        height: 200px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f4f4f4;
    }
    .career_info_card_title {
        margin: auto;
        font-weight: 600;
        font-size: medium;
    }
  }

@media screen and (max-width: 1000px) {
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr { 
      display: block; 
    }
  
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    tr { border: 1px solid #ccc; }
    
    td { 
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee; 
      position: relative;
      padding-left: 50%; 
    }
  
    td:before { 
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%; 
      padding-right: 10px; 
      white-space: nowrap;
    }
  }


  
@media screen and (max-width: 865px) {
    .career_info_card_body {
        width: 30rem;
        height: 250px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f4f4f4;
    }
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr { 
      display: block; 
    }
  
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    tr { border: 1px solid #ccc; }
    
    td { 
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee; 
      position: relative;
      padding-left: 50%; 
    }
  
    td:before { 
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%; 
      padding-right: 10px; 
      white-space: nowrap;
    }
  }


    
@media screen and (max-width: 720px) {
    .career_info_card_body {
        width: 20rem;
        height: 180px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f4f4f4;
    }

    .career_info_card_title {
        margin: auto;
        font-weight: 600;
        font-size: medium;
    }

    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr { 
      display: block; 
    }
  
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    tr { border: 1px solid #ccc; }
    
    td { 
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee; 
      position: relative;
      padding-left: 50%; 
    }
  
    td:before { 
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%; 
      padding-right: 10px; 
      white-space: nowrap;
    }
  }


      
@media screen and (max-width: 650px) {
    .career_info_card_body {
        width: 16rem;
        height: 180px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f4f4f4;
    }

    .career_info_card_title {
        margin: auto;
        font-weight: 600;
        font-size: small;
    }

    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr { 
      display: block; 
    }
  
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    tr { border: 1px solid #ccc; }
    
    td { 
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee; 
      position: relative;
      padding-left: 50%; 
    }
  
    td:before { 
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%; 
      padding-right: 10px; 
      white-space: nowrap;
    }
  }


        
@media screen and (max-width: 480px) {
  .career_info_card_body {
      width: 20rem !important;
      height: 220px !important;
      display: flex;
      justify-content: left;
      align-items: left;
      background-color: #f4f4f4;
  }

  .career_info_card_title {
      margin: auto;
      font-weight: 600;
      font-size: medium;
  }

  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr { 
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr { 
    position: absolute;
    top: -9999px;
    left: 5px !important;
  }

  tr { border: 1px solid #ccc; }
  
  td { 
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee; 
    position: relative;
    padding-left: 3% !important; 
  }

  td:before { 
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    width: 45%; 
    padding-right: 10px; 
    white-space: nowrap;
  }
}