.modal-dialog {
  max-width: 700px;
  margin-top: 2%;
}
.modal-content {
  width: 700px;
  border-radius: 30px;
}
.modal-header {
  border-radius: 30px;
}
.modal-body {
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .modal-dialog {
    max-width: 100%;
  }
  .modal-content {
    width: 100%;
  }
}
