.container {
  width: 90%;
  margin: auto;
}

.cardTitle {
  font-size: 17px;
  text-align: left;
  font-weight: 800;
}

.cardContent {
  font-size: 12px;
  font-weight: 500;
  color: #777;
}
