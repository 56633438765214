.pathContainer {
  margin-top: 19px;
  width: 100%;
  /* height: auto; */
  background-color: #f9f9f9;
}
.paths {
  display: flex;
  margin-left: 30px;
}
.paths p {
  color: #b9b9b9;
  font-size: 10px;
  margin: 4px;
}
.paths span {
  margin: 0 10px;
}
.paths .activePath {
  color: #2849ad;
}
.shopListing {
  display: flex;
  justify-content: center;
}
.shopProducts {
  width: 68%;
}

/* ! =============== user filter component ===================  */
.shopFilter {
  /* width: 365px; */
  min-width: 150px;
}
.shopFilter .filter {
  font-weight: 700;
  font-size: 14px;
  margin: 10px 0;
  cursor: pointer;
}
.shopSort .dropdown button {
  color: #666;
  font-weight: 600;
  font-size: 12px;
  background-color: #f1f1f1;
  border: 0;
}
.shopSort .form-label {
  color: #333;
  font-size: 12px;
  font-weight: 700;
}

.shopSort .form-select {
  width: 355px;
  box-shadow: none;
  font-size: 12px;
  background-color: #f1f1f1;
  color: #666;
}
.inputPrice input {
  font-size: 12px;
  background-color: #f1f1f1;
}
.inputPrice input:focus {
  box-shadow: none;
}
.shopSort .form-check-label {
  color: #333;
  font-size: 12px;
}
.btn-filter{
  background-color: #2849ad;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin:10px auto;
  width: 90%;
}
.btn-filter:hover{
  background-color: #2849add2;
}
/*! =============== user filter component =================== */

.productAvilable {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productAvilable button {
  padding: 2px 4px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin: 4px 15px;
  font-size: 12px;
  color: #666;
}
.shopProducts .productAvilable {
  border-bottom: 1px solid #dee2e6;
  font-size: 14px;
  margin-top: 35px;
}
.shopProducts .productAvilable p {
  margin: 20px;
  color: #00477b;
}
.userShopSliderCont {
  width: 95%;
  margin: 20px auto;
}

.productAvilable {
  color: #b9b9b9;
  padding: 4px 8px;
  font-size: 10px;
}


.productAvilableArrow{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  color: #fff;
  padding: 10px 16px;
  font-size: 15px;
  cursor: pointer;
  /* border: 1px solid #dee2e6; */
  border-radius: 8px;
  background-color: #171717;
}
.productAvilableArrow:hover{
  background-color: #2849ad;
  color: #fff;
  transition: all .3s ease-in-out;
}

/* * start user search product */
.userShopSearch input::placeholder{
  color: #b9b9b9;
}
.userShopSearch input{
  font-size: 12px;
  width: 225px;
  /* margin-bottom: 10px; */
}
.userShopSearch input:focus{
  box-shadow: none;
}
/* * end user search product */



/*! =============== Responsive view ===================  */
@media only screen and (max-width: 768px) {
  .pathContainer{
    margin-top: 0px;
  }
.shopListing {
    display: flex;
    flex-direction: column;
  }
  .shopProducts .productAvilable{
    display: flex;
    flex-direction: column;
  }
  .shopProducts{
    margin: auto;
  }
  .shopFilter{
    width: 80%;
    margin: auto;
  }
  .shopFilter button{
    margin: 10px auto;
  }
}
/* @media only screen and (min-width: 990) and (max-width: 1270px) {
  .shopProducts{
    background-color: rebeccapurple;
  }
} */