.firstSection {
  display: flex;
  justify-content: center;
  margin: 60px 0 30px 0;
}

.customerServ {
  width: 300px;
  height: 300px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #d2eaf0;
}
.customerServ span {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #e2f6fb;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
}
.customerServ h5 {
  font-weight: 700;
  color: #2849ad;
}

.customerServ p {
  font-size: 13px;
  text-align: center;
  color: #888;
}

.shopListingSlider {
  width: 770px;
  height: 300px;
  border-radius: 20px;
  margin-right: 20px;
}

.secondSection {
  margin: 30px 0 60px 0;
}

.secondSection h4 {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 40px;
  font-size: 30px;
  font-weight: 700;
}



/* .shopslider2img {
  width: 770px !important;
  height: 300px !important;
} */



@media only screen and (max-width: 994px) {
  .firstSection {
    flex-direction: column;
    align-items: center;
  }
  .firstSection .customerServ {
    margin-top: 40px;
  }



  .shopListingSlider {
    width: 550px !important;
    height: 200px !important;
  }
}

@media (max-width: 600px) {
  .shopListingSlider {
    width: 440px !important;
    height: 150px !important;
  }
  
  .firstSection .customerServ {
    margin-top: 270px !important;
  }

}
