.container {
    width: 90%;
    margin: auto;
    /* min-height: 400px;
    height: auto; */
  }

  .react-multi-carousel-list button {
    left: 100px;
  }
  
  .card_body {
    width: 15rem;
    height: 19rem;
    position: relative;
  }
  
  .img_container {
    width: 100%;
    height: 15rem;
    background-color: white;
    overflow: hidden;
  }
  
  .card_img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100% !important;
    object-fit: fill !important;
  }
  
  .content_container {
    top: 0%;
    width: 100%;
    height: 10rem !important;
    background-color: white;
    overflow: hidden;
  }
  
  .card_title {
    font-size: 17px;
    text-align: left;
    font-weight: 800;
  }
  
  .card_content {
    font-size: 14px !important;
    text-align: left;
    font-weight: 500;
  }
  
  /* .slider_card_body {
    width: 8rem;
    height: 18rem;
  } */
  
  .slider_img_container {
    margin: 1rem;
    width: 12rem;
    height: 7rem;
    background-color: white;
    overflow: hidden;
  }
  
  .slider-card-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .slider_content_container {
    top: 0%;
    width: 12rem;
    height: 4rem;
    background-color: white;
    overflow: hidden;
  }
  
  .slider_card_title {
    font-size: 17px;
    text-align: left;
    font-weight: 800;
  }
  
  .slider_card_content {
    font-size: 10px;
    text-align: left;
    font-weight: 500;
  }
  .userShopSlider .card_body {
    width: 18rem !important;
    height: 30rem;
  }
  .pr_cardBtn {
    display: flex;
    justify-content: space-around;
    width: 142px;
  }
  .pricing {
    color: #2b5279;
    font-weight: 700;
    font-size: 15px;
  }
  
