
.custom-width {
    width: 500px; 
    max-width: 1000px; 
    margin: 0 auto;
  }


.inputArea {
    background-color: #f4f6f8;
    box-shadow:  0px 0px 10px rgba(0, 0, 0, 0.05)
}

.inputArea::placeholder {
  color: #7acfd3;
}

.inputAreaPadding {
  margin-bottom: 20px;
}

.button-no-border {
  border: none;
}


.nav-tabs .nav-link {
  background-color: transparent;
  color: #7acfd3 !important;
  font-weight: 500;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
  color: #2849ad!important; 
  border-bottom: 3px solid #2849ad!important;
  font-weight: 600;
}

.nav-tabs .nav-link .login-tab-title {
  font-size: 14px;
  font-weight: 500;
}

.button3D {
  background-color: #2849ad;
  box-shadow: 0 5px #f4f6f8;
  font-weight: 600;
  font-size: 16px;
  transform: translateY(4px);
}

.button3D:hover {
  background-color: #5cb0b4;
  border-color: white;
}


.button3D:active {
  box-shadow: 0 2px #f4f6f8;
  transform: translateY(2px);
}

.bottom-note {
  display: flex;
  flex-direction: row;
}

.bottom-note p {
  margin-right: 0px !important;
  font-weight: 600;
}

.a-tag1 {
  margin-left: 5px;
  color: rgb(255, 49, 49);
}

.a-tag2 {
  margin-left: 45px;
  font-weight: 600;
  color: #2849ad;
}

@media (max-width: 768px) {
  .custom-width {
    width: 350px;
  }

  .bottom-note {
    flex-direction: column;
  }
  .a-tag2 {
    margin-left: 0;
  }
}
