.slick-dots ul li button::before {
    color: #2849ad !important;
    border-radius: 50%;
    content: "";
    width: 9px;
    height: 9px;
    background-color: #2849ad;
  }

.slick-dots li.slick-active button:before {
    color: #2849ad !important;
    opacity: 1 !important;
  }