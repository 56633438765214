.btn{
background-color: #ebf5ff;
border: 0;
padding: 8px 10px;
margin: 15px 0 15px 0;
color: #2b5279;
cursor: pointer;
border-radius: 10px;
box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
float: left;
font-size: 12px;
font-weight: 800;
}
.btn:hover{
    background-color: #2b5279;
    color: #fff;
    transition: all .3s;
}