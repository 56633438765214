.form {
  width: 140px;
}

.search {
  font-size: 12px;
  padding: 5px;
  /* width: 120px; */
}
.search:focus {
  box-shadow: none;
  border: 1px solid #198754;
}

.btnLog {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbarContainer {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 1000;
  height: 100%;
}


.btnLog .btnLogin {
  margin: 8px;
  font-size: 14px;
  /* padding: 5px 30px; */
  width: 100px;
}

.btnLog .btnSignup {
  margin: 8px;
  font-size: 14px;
  /* padding: 5px 20px; */
  width: 105px;
}

.btnSignup {
  background-color: #2849ad;
  border: 2px solid #2849ad;
  color: #fff;
}

.btnLogin {
  border: 2px solid #7acfd3;
  color: #7acfd3;
  background-color: #fff;
}
.btnLogin:hover {
  background-color: #7acfd3;
  color: #fff;
  border: 2px solid #7acfd3;
}

.cartContainer {
  cursor: pointer;
  background-color: #7acfd3;
  border: 0;
  border-radius: 4px;
  position: relative;
  width: 95px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 8px;
}

.cartContainer span {
  color: #fff;
  background-color: #2849ad;
  width: 23px;
  height: 23px;
  text-align: center;
  border-radius: 50%;
}

/* .rightSide {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.translate {
  font-size: 12px;
  padding: 0 20px;
}
.transparentBackground,
.dropdownButton {
  background-color: transparent !important;
}

.customDropdown[data-bs-popper] {
  left: -1px;
  font-size: 12px;
  background-color: rgba(7, 12, 27, 0.5);
  --bs-dropdown-link-color: #fff;
  --bs-dropdown-min-width: 4rem !important;
}
