.career {
  background-image: url("../../../public/img/info2-back.png");
  background-repeat: repeat;
}

.toptInfo p {
  font-size: 13px;
  padding: 10px;
}

.careerSlider1,
.careerSlider2 {
  margin: 50px 0 50px 0;
}
.careerSlider1 h5,
.careerSlider2 h5 {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 50px;
  font-weight: 700;
}

.productSlider {
  margin: 50px 0 50px;
}

.jobResults {
  display: flex;
  justify-content: space-between;
  color: #00477b;
  background-color: #f8f8f8;
}
.jobResults b {
  margin: 5px;
}

.jobResults h2 {
  font-size: 20px;
  font-weight: 500;
}

.styled_list_item {
  width: 100%;
  color: #003a64 !important;
  text-align: center !important;
  cursor: pointer;
  font-size: 14px;
}

.styled_list_item:hover {
  background-color: #7acfd3;
  font-weight: 600;
}

.filter {
  background-color: #f8f8f8;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
}

.filterContent {
  width: 93%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.filter button {
  background-color: #00477b;
  color: #fff;
  padding: 8px 70px 8px 70px;
  font-weight: 500;
  border-radius: 20px;
  font-size: 12px;
}
.filter button:hover {
  background-color: #003a64;
}

@media only screen and (max-width: 900px) {
  .jobResults h2 {
    font-size: 18px;
    font-weight: 500;
  }

  .jobResults span,
  .jobResults b {
    font-size: 14px;
  }

  .filterContent {
    flex-direction: column;
    margin: 30px;
  }
  .filterContent div {
    width: 100%;
    margin-bottom: 25px;
  }
  .filter {
    height: auto;
  }
}


@media only screen and (max-width: 480px) {
  .careerSlider2 {
    margin: 0px !important;
    margin-bottom: 20% !important;
  }

  .careerSlider2 h5 {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 10px;
  }
}
