.PopupEditInfo .modal-dialog {
  max-width: 900px;
  margin-top: 2%;
}
.PopupEditInfo .modal-content {
  width: 900px;
  border-radius: 30px;
}

.PopupEditInfo .modal-body {
  max-height: 80vh;
  overflow-y: auto;
  padding: 0;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .PopupEditInfo .modal-dialog {
    max-width: 100%;
  }
  .PopupEditInfo .modal-content {
    width: 100%;
  }
}
