.RelatedProductSlider .slick-next:before,
.productImage .slick-next:before,
.RelatedProductSlider .slick-prev:before,
.productImage .slick-prev:before {
  font-size: 25px;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  width: fit-content;
  display: block;
  width: 190%;
  text-align: center;
  height: 188%;
}
.RelatedProductSlider .slick-prev:before,
.productImage .slick-prev:before {
  content: "\276E";
}
.RelatedProductSlider .slick-next:before:hover,
.productImage .slick-next:before:hover {
  color: #fff;
  background-color: aqua;
}
.RelatedProductSlider .slick-next:before,
.productImage .slick-next:before {
  content: "\276F";
}

.productImage .slick-prev:before {
  position: fixed;
  left: 60%;
}
.productImage .slick-next:before {
  position: fixed;
  right: 60%;
}
