.test .subListing {
  background-color: #f6fbfb;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 11;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: auto;
  box-sizing: border-box;
}

.test .navbar-nav .nav-link {
  text-align: center;
  padding-top: 25px;
  font-size: 12px;
  font-weight: 500;
  color: #2849ad;
  /* flex-wrap: wrap; */
}


.dropdown{
  position: relative;
  display: inline-block;
}

.dropdown-content{
  display: block;
  position: absolute;
  background-color: #f5f5f5;
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  /* max-height: 300px; */
  /* flex-wrap: wrap; */

  width: auto;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 10px;
  border-radius: 5px;

  left: 50%;
  transform: translateX(-50%);
}

.dropdown-content a {
  color: #2849ad;
  font-weight: 600;
  font-size: 12px;
  padding: 5px 12px;
  text-decoration: none;
  display: block;
  text-align: left;
  width: 150px;
}
.dropdown-content a:hover {background-color: #4ebfc4;color: #fff;}