
  .slider-container .slick-list {
    margin: 0 30px; 
  }
  
  .slider-container .slick-slide > div {
    padding: 0 20px; 
  }

.slick-dots li button:before {
  font-size: 14px !important;
  color: #2849ad !important;
  top: 10px;
}
.slick-dots li.slick-active button:before {
  opacity: 1 !important;
}


.career3-card {
  width: 30rem !important;
  height: 16rem !important;
  color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: flex-end;
}

.card1 {
  background: linear-gradient(to top, rgba(40,73,173,0.8), rgba(255,255,255,0)),
                url("../../../public/img/career/14.png");
}

.card2 {
  background: linear-gradient(to top, rgba(40,73,173,0.8), rgba(40,73,173,0.3)),
                 url("../../../public/img/career/15.png");
}

.card3 {
  background: linear-gradient(to top, rgba(40,73,173,0.8), rgba(40,73,173,0.2)),
                 url("../../../public/img/career/16.png");
}

.card4 {
  background: linear-gradient(to top, rgba(40,73,173,0.8), rgba(255,255,255,0)),
                 url("../../../public/img/career/13.png");
}

.card5 {
  background: linear-gradient(to top, rgba(40,73,173,0.8), rgba(255,255,255,0)),
                 url("../../../public/img/career/12.png");
}

.career3-card-body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; 
  width: 95%;
  height: 95%;
}

.career3-card-text {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #e9f6f4;
  margin: 0;
  font-family: 'Bradley Hand', Arial, sans-serif !important;
}


@media screen and (max-width: 1300px) {
  .career3-card {
    width: 25rem !important;
    height: 16rem !important;
  }
}

@media screen and (max-width: 1024px) {
  .career3-card {
    width: 25rem !important;
    height: 16rem !important;
  }
}

@media screen and (max-width: 768px) {
  .career3-card {
    width: 23rem !important;
    height: 16rem !important;
  }
}

@media screen and (max-width: 520px) {
  .career3-card {
    width: 16rem !important;
    height: 20rem !important;
  }

  .career3-card-text {
    font-size: 15px !important;
    font-weight: 600 !important;
  }
}