.sliderContainer {
  width: 100%;
  overflow: hidden;
}

.slider {
  position: relative;
}

.slider img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.sliderContent {
  position: absolute;
  top: 25%;
  left: 100px;
  width: 400px;
  padding: 40px;
  border-radius: 30px;
  z-index: 1;
  color: #172961;
}
.sliderContent h1 {
  font-size: 40px;
  font-weight: 700;
}
.sliderContent h5 {
  font-size: 13px;
  font-weight: 500;
  margin: 25px 0;
  font-weight: 700;
}
.sliderContent p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  font-weight: 700;
}
.sliderContent button {
  margin: 0;
  font-weight: 600;
}
.display {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2849ad;
  cursor: pointer;
}

.displayPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 700px){
  .sliderContent{
    left: 50px;
  }
}

@media (max-width: 489px){
  .sliderContent{
    left: 0px;
  }
}

