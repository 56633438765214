.hover-container {
  position: relative;
  display: inline-block;
}

.hover-label {
  visibility: hidden;
  position: absolute;
  top: -20px; /* Adjust as needed */
  left: 0;
  background-color: #f8f9fa;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 14px;
  color: #2849ad;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hover-container:hover .hover-label {
  visibility: visible;
}
