.btn{
background-color: #7acfd3;
border: 0;
padding:10px 20px ;
margin: 40px;
color: #fff;
cursor: pointer;
border-radius: 10px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.btn:hover{
    background-color: #5cb0b4;
    transition: all .3s;
}