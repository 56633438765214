#vshopSlider .slick-slide > div {
  margin: 0 -20px;
}

.slick-next:before,
.slick-prev:before {
  color: #4ebfc4;
  font-size: 25px;
}

/* !test untill insuring the whole cards stay the same  */
.card {
  width: 95% !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  height: auto;
  border: 0;
  margin: 5px;
  border-radius: 10px;
}
.card img {
  border-radius: 15px;
}

/* .slider-container .slick-initialized .slick-slide div{
  display: flex!important;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
} */
