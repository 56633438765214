.navbar-nav .nav-link,
.navbar-nav .page-nav {
  color: #4bad94;
  font-weight: 500;
  height: 100%;
}

.container,
.container-fluid {
  padding-left: calc(var(--bs-gutter-x) * 1.5);
}
.navbar-brand {
  margin: 0;
  padding: 0;
}

.navbar-nav .nav-link.active,
.navbar-nav .page-nav.active {
  font-weight: 700;
  background-color: #ebeff6;
  color: #2849ad !important;
  border-bottom: 2px solid #2849ad !important;
}

#client.nav-link,
#client.page-nav {
  color: #2849ad !important;
  font-weight: 520 !important;
  height: 100%;
  font-size: 17px !important;
}

#client.nav-link.active,
#client.page-nav.active {
  font-weight: 700;
  background-color: #dbfdf4;
  color: #2849ad !important;
  border-bottom: 3px solid #4bad94 !important;
}


.custom-toggle.dropdown-toggle::after {
  display: none;
}

.custom-menu.dropdown-menu {
  font-weight: 500;
}

.custom-menu.dropdown-menu .dropdown-item {
  color: #4bad94 !important;
  font-size: 14px;
  font-weight: 600;
}

.custom-menu.dropdown-menu .dropdown-item:hover {
  color: #2849ad !important;
}

.dropdown.translate .btn.dropdown-toggle {
  display: inline-flex;
  align-items: center;
}
