
.accordion {
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 15px;

}

.item {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 10px 12px;
  padding-right: 24px;
  cursor: pointer;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 12px;
  row-gap: 15px;
  align-items: center;
  
}

.number {
  font-weight: 500;
  color: #ced4da;
}

.title,
.ficon {
  font-weight: 600;

}
.ficon{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #15294f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.content-box {
  grid-column: 2 / -1;
  padding-bottom: 8px;
  line-height: 1.6;
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.content-box ul {
  color: #868e96;
  margin-left: 8px;
  margin-top: 8px;

  display: flex;
  flex-direction: column;
  gap: 6px;
}

/* OPEN STATE */
.open {
  border-top: 2px solid #2849ad;
}
.open .number,
.open .title {
  color: #2849ad;
}


/* Add the following to your existing CSS */

@media (max-width: 768px) {
  .accordion {
    width: 100%;
  }

  .item {
    width: 100%;
    padding: 5px;
  }

  .icon {
    font-size: 16px !important;
  }

  .content-box {
    padding-top: 8px;
  }
  .title{
    font-size: 14px;
  }
}
