.toptInfo p {
  font-size: 13px;
  padding: 10px;
}
.careerSlider1,
.careerSlider2 {
  margin: 50px 0 100px 0;
}
.careerSlider1 h5,
.careerSlider2 h5 {
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 700;
}

.careerSlider2 h5 {
  margin: 50px;
  font-size: 30px;
}

.careerSlider1 {
  background-color: #f4f4f4;
  padding: 50px;
}
.careerSlider1 .careerSliderCards1 {
  padding: 50px;
}
.careerSlider2 {
  margin: auto;
  margin-bottom: 100px !important;
}

.careerSlider1 h5 {
  font-size: 26px;
}

@media (max-width: 480px) {
  .careerSlider1 h5,
  .careerSlider2 h5 {
    text-align: center;
  }

.careerSlider1,
.careerSlider2 {
  margin: 5px;
  padding: 0px !important;
  margin-left: 0% !important;
}
}