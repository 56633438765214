.first-job-detail-slider .slick-dots {
  bottom: 20%;
  left: -33%;
}

.job-detail-slider img {
  background-position: center;
  object-fit: cover;
  background-size: cover;
  border-radius: 20px;
  position: relative;
}

.content-Slider{
  position: absolute;
  top: 10%;
  left: 5%;
  width: 300px;
  margin: 50px;
}
.content-Slider h5{
font-weight: 600;
}
.content-Slider button{
  margin: 0;
}



@media (max-width: 789px) {
  .first-job-detail-slider {
    margin-top: 20%;
  }
}


