.modalTitle {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.inputBox {
  border: 1px solid #e8ecf0;
  background-color: #f4f6f8e7;
}
.inputBox:focus,
.dateInput:focus {
  box-shadow: none;
}
.inputBox::placeholder {
  color: #888;
  font-weight: 300;
  font-size: 14px;
}

.dateInput {
  border: 1px solid #e8ecf0;
  background-color: #f4f6f8e7;
  color: #888;
}

.formBackground {
  background-color: #fff;
}
