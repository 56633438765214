.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 100%; 
  flex-direction: row; 
}

.image {
  border-radius: 10%;
  width: 480px;
  height: 250px;
  background-size: cover;
  background-position: center;
  margin-bottom: 100px;
}

.textContainer {
  width: 520px; 
  height: 250px;
  margin-top: 20px;
  margin-bottom: 100px;
}

.textContainer h4 {
  flex-grow: 1;
  line-height: 1.5;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 10px;
}

.textContainer p {
  flex-grow: 1;
  line-height: 1.5;
  font-weight: 500;
}

.linkStyle {
  color: #2849ad;
  text-decoration: underline;
  cursor: pointer;
  font-size: large;
}

.linkStyle:hover {
  font-weight: 600;
}

@media (max-width: 1050px) {
  .textContainer {
    margin-left: 100px;
    margin-bottom: 30px;
  }

  .container {
    flex-direction: column !important;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .textContainer {
    margin-left: 5px;
    margin-bottom: 30px;
    width: 375px;
    height: auto;
  }

  .image {
    width: 380px;
  }
}
