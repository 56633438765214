.form-group .submit {
  background-color: rgb(0, 71, 123);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 8px 50px;
  font-size: 13px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  margin: 20px auto;
  display: block;
}
.form-group .close {
  float: right;
  color: #fff;
  margin-bottom: 15px;
}

.form-group .form-label {
  font-size: 15px;
  font-weight: 600;
  color: #333;
}
.form-group .form-control:focus {
  box-shadow: none;
}
