.productImage {
  width: 700px;
  height: 450px;
  border-radius: 10px;
  overflow: hidden;
}
.productImage .slick-dots {
  bottom: 18%;
}
.productImage .slick-prev {
  left: 20px;
  z-index: 111;
}

.productImage .slick-next {
  right: 20px;
}
.productImage .imgSlider {
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
/* !$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

@media (max-width: 576px) {
  .productImage {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .productImage {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .productImage {
    width: 90%;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .productImage {
    width: 60%;
  }
}
