.sec11 {
  background-color: #ffffff;
  padding: 30px;
  position: relative;
  margin-top: 5%;
  margin-bottom: 5%;
}

.sec11::before{
  content: url('../../../public/img/colred-2-Ls.png');
   position: absolute;
   top: 25%;
   left: 15px;
}

.sec11::after{
  content: url('../../../public/img/colred-1-Ls.png');
  position: absolute;
  top: 20%;
  right: 30px;
}
  
.contact {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}
.contactForm {
  background-color: transparent;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #dfdfdf;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.informations {
  background-color: transparent;
  padding: 40px;
  padding-top: 20px;
  border-radius: 20px;
  border: 1px solid #dfdfdf;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
}
.contact .contactForm h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  font-size: 24px;
  font-weight: 700;
  border-bottom: 2px solid #dfdfdf;
}
.contact .infoContent {
  margin: 40px 15px;
  font-size: 14px;
}

.contact .informations h5 {
  top: 0;
  padding-bottom: 15px;
  padding-top: -10px; 
  font-size: 24px;
  font-weight: 700;
  border-bottom: 2px solid #dfdfdf;
}
.informations div {
  margin-top: 20px;
}
.informations span {
  padding-right: 15px;
  font-size: 18px;
}

.sec11 img{
  width: 100%;
  height: auto;
  object-fit: cover;
}

.sec11 .title{
  position: absolute;
  top: -20%;
  left: 50%;
  font-size: 50px;
  transform: translate(-50%, -50%);
  color: #000;
  font-family: 'Arial', sans-serif;
  font-weight: 600; /* Change the font */
}

@media (max-width: 1100px) {

  .sec11 {
    /* margin-top: 20%; */
    padding: 35px;
  }
  .contact {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  .contactForm{
    padding: 35px;
  }

  .contactForm h5 {
    padding-left: 25px !important;
  }

  form{
    padding: 0;
  }

  .sec11 .title {
    font-size: 40px;
    top: -10%;
  }
  .sec11::before, .sec11::after{
    display: none;
  }
}


@media (max-width: 768px) {
  .sec11 {
    margin-top: 20%;
  }
  .sec11 .title {
    font-size: 40px;
    margin-top: 10%;
  }

  .contactForm h5 {
    padding-left: 25px !important;
  }

  .contactForm{
    padding: 15px;
  }
}
