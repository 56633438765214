/* Taken from getting started guide at: https://leafletjs.com/examples/quick-start/ */
@import 'https://unpkg.com/leaflet@1.9.3/dist/leaflet.css';
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700;800&display=swap';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

::selection {
  background-color: var(--color-brand--2);
  color: #fff;
}

@layer components {
  .input {
    @apply rounded-lg border border-stone-200 px-3 py-1 text-sm transition-all duration-300 placeholder:text-stone-400 focus:outline-none md:px-3 md:py-2;
  }
  .label {
    @apply text-base font-semibold text-custom-blue sm:basis-48;
  }
  .popup_review_label {
    @apply text-sm font-semibold text-custom-blue sm:basis-48;
  }
  .hover{
    @apply transition-transform duration-300 ease-in-out hover:scale-105
  }
  .animation{
    @apply hover:-translate-y-2 transition-transform duration-700
  }
}

:root {
  --color-brand--1: #2849ad;
  --color-brand--2: #07b1ab;
  --color-dark--0: #242a2e;
  --color-dark--1: #2d3439;
  --color-dark--2: #42484d;
  --color-light--1: #aaa;
  --color-light--2: #ececec;
  --color-light--3: #d6dee0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Open Sans', sans-serif;
}



/*!============ test1 =============*/
.card {
  transition: transform 0.3s ease;
}
.card:hover {
  transform: scale(0.95);
}
/*!============ test1 =============*/

/*!============ test2 =============*/
input:focus,
.form-control:focus,
textarea:focus {
  border-color: #66afe9 !important;
  outline: 0 !important;
  -webkit-box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6) !important;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6) !important;
}
/*!============ test2 =============*/

/*!============ test3 =============*/
.slick-prev:before,
.slick-next:before {
  color: #07b1ab !important;
}
/*!============ test3 =============*/



/* <div className="w-10 h-10 rounded bg-blue-500 hover:-translate-y-2 transition-transform duration-700">
test
</div> */