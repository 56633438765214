.bottomInfo {
  width: auto !important;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(../../../public/img/footer-info.png);
}

/* .bottomInfo h4 {
  font-size: 16px;
} */

.bottomInfo .btitle {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
}

.inputInfo button {
  border: 1px solid #2849ad;
  background-color: #2849ad;
  padding: 8px 30px;
  border-radius: 5px;
  margin: 20px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  width: 120px;
  transition: all 0.3s ease-in-out;
}

.inputInfo button:hover {
  background-color: rgb(28, 51, 119);
}



.findJobInputInfo button {
  border: 1px solid #2849ad;
  background-color: #2849ad;
  padding: 8px 30px;
  border-radius: 5px;
  margin: 20px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  width: 120px;
  transition: all 0.3s ease-in-out;
}

.findJobInputInfo button:hover {
  background-color: rgb(28, 51, 119);
}


.findJobInput {
  position: relative;
  padding: 8px;
  width: 400px;
  height: 40px;
  top: 20px;
  border-radius: 5px;
  font-size: 12px;
  border: 0;
  outline: 0;
  color: #15294f;
}





.inputInfo {
  position: relative;
  display: flex !important;
  flex-direction: row !important;
  justify-items: center;
}



.findJobInputInfo {
  position: relative;
  display: flex !important;
  flex-direction: row !important;
  justify-items: center;
}


.inputInfo span {
  position: absolute;
  right: 31%;
  top: 50%;
  transform: translateY(-50%);
}





.findJobInputInfo span{
  position: absolute;
  right: 5% ;
  top: 65%;
}

.findJobInput::placeholder,
.inputInfo span,
.findJobInputInfo span {

  color: #b5b5b5;
}

@media only screen and (max-width: 600px) {
  .inputInfo {
    display: flex;
    flex-direction: column !important;
    align-items: center;
  }
  .inputInfo span {
    right: 2%;
    top: 30%;
  }

  .inputInfo button {
    margin: 5;
  }

  .findJobInputInfo {
    display: flex;
    flex-direction: column !important;
    align-items: center;
  }

  .findJobInputInfo button {
    margin-top: 20%;
    margin: 10;

  }
}


@media only screen and (max-width: 600px) {
  .findJobInput {
    width: 350px;
  }
}