.dsmBackground {
    background-image: url('../../../public/img/info2-back.png');
    background-repeat: repeat; 
  }


  .container {
    transition: transform 500ms;
  }
  
  .container.triggered {
    transform: translateY(0);
  }

  .dsm {
    margin: 30px;
    height: 130px;
  }

  .about {
    margin: 30px;
    height: 150px;
  }

  .dsmtitle {
    font-family: 'Open Sans', sans-serif;
    position: absolute;
    top: 25%;
    left: 16%;
    font-size: 32px;
    font-weight: 700;
    color: black;
    margin: 0px;
    justify-content: left;
    text-align: left;
  }

  @media (max-width: 1000px) {
  }

  @media (max-width: 768px) {

  }