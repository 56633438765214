.chatReview-modal {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: hidden;
}

.chatReview-modalContent {
  background-color: #fff;
  width: 20%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.chatReview-card {
  padding: 20px 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.chatReview-messageList .rce-container-mbox{
min-height: 165px;
}

.chatReview-messageList {
  flex: 1;
  overflow-y: auto;
}
.chatReview-messageList .rce-mlist{
  overflow: hidden;
}
.chatReview-messageList .rce-mbox {
  background-color: #4cbfc2;
  color: #fff;
  border-radius: 8px;
  width:60% ;
  margin: 20px;
  /* min-height: 60px; */
  height: auto;
}

.chatReview-messageList .rce-mbox.rce-mbox-right {
  background-color: #f0f0f0 ;
  color: #000;
}


