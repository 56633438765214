.infoCard {
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 20px;
  }

.whySec {
    padding-top: 10px;
    text-align: center;
}

.whySec span{
    color: #4ab590 !important;
    font-weight: 500;
    font-style: initial;
    text-align: center;
}

.answer {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}