.message-list .rce-mbox.rce-mbox-right {
  background-color: #e8feff;
  border-radius: 8px;
}
.rce-mbox-right-notch,
.rce-mbox-left-notch {
  display: none;
  }
  div.rce-mbox {
    background-color: #f3f3f3;
    border-radius: 8px;
    box-shadow: none;
    min-width: 45%;
}
.rce-citem{
  border-radius: 10px;
  }
  .rce-citem:hover{
  background-color: #f1f1f1;
}
.rce-citem-body--top-title{
  font-size: 12px;
  font-weight: 700;
}
.rce-citem-body--bottom-title{
  font-size: 12px;
  font-weight: 500;
  color: #666;
}
.rce-citem-body--top-time{
  font-size: 10px;
}
div.rce-container-clist.chat-list.rounded{
  background-color: #fff;
  margin-top: 10px;
}
.rce-mbox-time .non-copiable::after{
  background-color: #666;
}
div.rce-citem{
  height: 50px;
}
.current-chat div.rce-citem{
  background-color: #4cbfc2;
  }
.current-chat div.rce-citem,
.current-chat .rce-mbox-time .non-copiable::after,
.current-chat .rce-citem-body--bottom-title,
.current-chat .rce-citem-body--top-time{
  color: #fff;
}
.rce-citem-body--bottom-title{
  font-size: 11px;
}

.company-chat  div.rce-citem{
  background-color: #fff;
  margin-bottom: 10px;
}

.company-chat  div.rce-citem-body{
border-bottom: 0;
}

.rce-avatar-container .rce-avatar{
  width: 70%;
  height: 70%;
}
.rce-mbox-text:after{
  display: none;
}