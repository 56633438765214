.my-component-container {
  width: 100%;
  height: 950px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-col,
.right-col {
  flex: 1;
}

.left-col {
  position: relative;
  height: 100%;
}

.right-col {
  height: 950px;
}

.background-image,
.partially-covered-image {
  width: 100%;
  height: 950px;
  background-size: cover;
  background-position: center;
}
  
.background-image {
  background-image: url('../../../public/img/sign-back.png');
}

.icon-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cffdff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.title {
  margin-top: 25px;
    text-align: center;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    font-size: 35px;
    font-weight: 600;
}

.noform-title {
  margin-top: 25px;
  text-align: center;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
  font-size: 22px;
  font-weight: 600;
}

.subTitle {
  text-align: center;
  color: black;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  margin: 10px;
  font-weight: 500;
}

.subTitle2 {
  text-align: center;
  color: #2849ad;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  margin: 10px;
  font-weight: 600;
}

.subTitle3 {
  text-align: center;
  color: #0d9488;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  margin: 10px;
  font-weight: 600;
}

.header-container {
  position: absolute;
  top: 18%;
  left: 25%;
}
  

.single-header-container {
  position: absolute;
  top: 25%;
  left: 25%; 
}
 

.header-container.no-form {
  top: 20%;
  left: 30%;
  width: 400px;
}


.header-container.signup {
  top: 10%;
  left: 25%;
}


.partially-covered-image {
  background-image: url('../../../public/img/sign/4.png');
}

@media (max-width: 1200px) {
  .single-header-container,
  .header-container {
    border-radius: 30px;
    background-color: rgba(186, 200, 244, 0.336);
    backdrop-filter: blur(15px);
  }

  .my-component-container,
  .right-col,
  .background-image,
  .partially-covered-image {
    height: 1000px;
  }
}

@media (max-width: 768px) {
  .header-container,
  .single-header-container,
  .header-container.no-form {
    left: 15%;
  }
}