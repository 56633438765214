.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.gallery {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;
}

.gallery-item {
  min-width: 100%;
  text-align: center;
}

.gallery-item img {
  width: 200px; /* Fixed width */
  height: 200px; /* Fixed height */
  object-fit: cover; /* Ensure the image covers the area without distortion */
}

.gallery-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.arrow {
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  padding: 10px;
  margin: 0 10px;
}