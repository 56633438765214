/* Style the table cells */

.htCore {
  width: max-content;
}

.htCore td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
  }
  
  /* Style the table headers */
  .htCore th {
    background-color: #7acfd3;
    color: black;
    font-size: 18px;
    font-weight: 500;
    padding: 8px;
    text-align: left;
    line-height: 40px;
    box-sizing: content-box;
  }
  
  /* Style the selected cells */
  .ht__highlight {
    background-color: yellow;
  }